import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ForgotPasswordService from '../services/ForgotPasswordService';
import { navigateToUrl } from "single-spa";
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import "../styles/styles.css";
import GetAdminData from '../services/GetAdminData';
import GetAdminImage from '../services/GetAdminImage';
import Link from '@mui/material/Link';
import EmailIcon from '@mui/icons-material/Email';

const defaultTheme = createTheme();

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [forgotPasswordCoverImage, setForgotPasswordCoverImage] = useState();

  useEffect(() => {
    const fetchAdminData = async () => {
      try {
        const adminData = await GetAdminData();
        const forgotPasswordCoverImageKey = adminData.forgotPasswordCoverImage;
        const forgotPasswordCoverImageKeyUrl = await GetAdminImage(forgotPasswordCoverImageKey);
        setForgotPasswordCoverImage(forgotPasswordCoverImageKeyUrl);
      } catch (error) {
        // console.error("Error occurred when getting data", error);
      }
    };
    fetchAdminData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      sessionStorage.setItem('email', email);
      const response = await ForgotPasswordService(email);
      const requestId = response.$metadata.requestId;
      setMessage('A password reset code has been sent to your email.');

      setTimeout(() => {
        navigateToUrl(`/signin/resetpassword/${requestId}`);
      }, 3000);
    } catch (error) {
      // setError('Unable to process your request. Please try again.');
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${forgotPasswordCoverImage})`,
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'left',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>

          <Box
            sx={{
              my: 20,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar
              sx={{
                m: 1,
                width: 64,
                height: 64,
                backgroundColor: 'black',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                borderRadius: '50%',
                backgroundSize: 'cover',
              }}
            >
              <EmailIcon />
            </Avatar>

            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '80%' }}>
              <Box mt={2} mb={1}>
                <Typography fontFamily={'serif'} lineHeight={1.5} sx={{
                  fontSize: { xs: '0.9rem', sm: '1.25rem', lg: '1.2rem' },
                }}>
                  Please input your email address, and we’ll send you a code to help you reset your password.
                </Typography>
              </Box>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoFocus
                onChange={(e) => setEmail(e.target.value)}
                className="gradient-textfield"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  fontWeight: 550,
                  letterSpacing: 2,
                  background: 'black',
                  "&:hover": {
                    background: 'black',
                  }
                }}
              >
                Submit
              </Button>

              <Grid container>
                <Grid item xs={12} sx={{ textAlign: 'left' }}>
                  <Link href="/signin" variant="body2" sx={{ textDecoration: 'none', color: 'black' }}>
                    Back to Sign In
                  </Link>
                </Grid>
              </Grid>

              {message && <Alert severity="success" sx={{ width: '100%', mt: 2 }}>{message}</Alert>}
              {error && <Alert severity="error" sx={{ width: '100%', mt: 2 }}>{error}</Alert>}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default ForgotPassword;

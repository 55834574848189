import ApiInstance from "../configs/ApiInstance";

async function ForgotPasswordService(email) {
    try {
        const response = await ApiInstance.userAwsService.post('/forgotpassword', {
            email
        });

        return response.data;

    } catch (error) {
        // return null;
        // console.error('There was a problem with the forgot password request:', error);
        // throw error;
    }
}

export default ForgotPasswordService;

import axios from "axios";
import configs from "./endpoints.json";

const userAwsService = axios.create({
    baseURL: `${configs.userAwsService}`
})

const adminService = axios.create({
    baseURL: `${configs.adminService}`
})

const apiInstance = {
    userAwsService,
    adminService
}

export default apiInstance;


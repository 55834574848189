import ApiInstance from "../configs/ApiInstance";

async function ResetPasswordService(email, verificationCode, newPassword) {
    try {
        const response = await ApiInstance.userAwsService.post('/resetpassword', {
            email,
            code: verificationCode,
            newPassword,
        });
        return response.data;
    } catch (error) {
        // console.error('There was a problem with the reset password request:', error.response ? error.response.data : error.message);
        // throw error;
    }
}


export default ResetPasswordService;

import ApiInstance from "../configs/ApiInstance"
import { navigateToUrl } from "single-spa";

async function SignInService(email, password) {
    try {
        const response = await ApiInstance.userAwsService.post('/signin', {
            email,
            password
        });

        const data = response.data;
        const { AccessToken, RefreshToken, UserDetails, UserId } = data;

        sessionStorage.setItem('accessToken', AccessToken);
        sessionStorage.setItem('refreshToken', RefreshToken);
        sessionStorage.setItem('user', JSON.stringify(UserDetails));
        sessionStorage.setItem('userId', UserId);
        navigateToUrl('/');
        window.location.reload();
        return data;

    } catch (error) {
        // console.error('There was a problem with the login request:', error);
        throw error;
    }
}

export default SignInService;
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SignIn from './components/SignIn';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';

function App() {
  return (
    <Router>
        <Routes>
            <Route path='/signin/' element={<SignIn/>} />
            <Route path='/signin/forgotpassword' element={<ForgotPassword/>} />
            <Route path='/signin/resetpassword/:requestId' element={<ResetPassword />} />
        </Routes>
    </Router>
  );
}

export default App;
